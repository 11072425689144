import "./styles.scss"

import React from "react"
import { Fade } from "react-awesome-reveal"
import Slider from "react-slick"

import ApartmentTile from "components/ApartmentTile"

import { useComparedApartments } from "context/comparison"
import { useLocale } from "context/locale"

const Content = () => {
  const { t } = useLocale()
  const { compared_apartments } = useComparedApartments()

  const slider = {
    dots: false,
    arrows: true,
    infinite: false,
    speed: 750,
    autoplay: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  }

  return (
    <section className="comparison-content">
      <div className="container-fluid">
        {compared_apartments.length > 0 ? (
          <div className="row g-0">
            <div className="col-xl-2 col-3">
              <div className="comparison-content__list comparison-content__list--main">
                <p>{t("Metraż")} (m2)</p>
                <p>{t("Pokoje")}</p>
                <p>{t("Piętro")}</p>
                <p>{t("Usytuowanie")}</p>
                <p>{t("Status")}</p>
              </div>
            </div>
            <div className="col-xl-10 col-9">
              <Slider {...slider}>
                {compared_apartments.map((item, index) => {
                  const data = item?.acfApartment || item?.acfOffice
                  return (
                    <div key={index}>
                      <Fade cascade triggerOnce>
                        <ApartmentTile item={item} comparison />
                      </Fade>
                      <div className="comparison-content__list">
                        <p>
                          {data?.area?.toString().replace(".", ",")} m
                          <sup>2</sup>
                        </p>
                        <p>{data?.roomsnumber}</p>
                        <p>{data?.floor ? data?.floor : 0}</p>
                        <p>{t(data?.location)}</p>
                        <p>
                          <strong className={data?.status}>
                            {data?.status === "available"
                              ? t("Wolne")
                              : data?.status === "reserved"
                              ? t("Zarezerwowane")
                              : t("Sprzedane")}
                          </strong>
                        </p>
                      </div>
                    </div>
                  )
                })}
              </Slider>
            </div>
          </div>
        ) : (
          <p>
            <div
              dangerouslySetInnerHTML={{
                __html: t("Brak <strong>mieszkań</strong> w porównywarce."),
              }}
            />
          </p>
        )}
      </div>
    </section>
  )
}

export default Content
